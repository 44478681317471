import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import EmojiPickerWrapper from './EmojiPickerWrapper';
import { useDropzone } from 'react-dropzone';
import Modal from './Modal';
import { useChatList } from '../context/ChatContext';
import { useWebSocket } from '../context/WebSocketContext';
import { useSidebar } from '../context/sidebarContext';
import MessageItem from './MessageItem';
import { useNotifications } from '../hooks/useNotifications';
import FileUpload from './FileUpload';
// import { getESTTimestamp } from '../utils/dateUtils';

const ChatRoom = React.memo(({ initialUsername }) => {
  const { slug, category } = useParams();
  const chatul = useRef(null);
  const chatInputRef = useRef(null);
  const { wsRef } = useWebSocket();
  const {
    messages,
    addMessage,
    addReaction,
    removeReaction,
    updateReaction,
    getMessageReactions,
    addImage,
    addHistoryMessages,
    clearChatHistory,
  } = useChatList();
  const [isConnected, setIsConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const [retryAttempt, setRetryAttempt] = useState(0);
  const currentRoomRef = useRef(slug);
  const reconnectTimeoutRef = useRef(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCaption, setSelectedCaption] = useState('');
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  const { updateRoomInfo, roomInfo } = useSidebar();
  const { showNotification } = useNotifications();
  const [hasMoreHistory, setHasMoreHistory] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMyMessage, setIsMyMessage] = useState(false);

  const wsUrl = React.useMemo(() => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const wsEndpoint = process.env.REACT_APP_CHAT_ENGINE_API_ENDPOINT?.replace(/^https?:\/\//, '') || 'localhost:8788';
    if (category) {
      return `${protocol}://${wsEndpoint}/category/${encodeURIComponent(category)}`;
    }
    return `${protocol}://${wsEndpoint}/room/${encodeURIComponent(slug || 'lobby')}`;
  }, [slug, category]);

  // Store stable references
  const callbacksRef = useRef({});
  const wsRefStable = useRef(wsRef);

  // Keep wsRef stable reference updated
  useEffect(() => {
    wsRefStable.current = wsRef;
  }, [wsRef]);

  // Separate connection status update logic
  const updateConnectionStatus = useCallback((status, attempt = 0) => {
    setConnectionStatus(status);
    setRetryAttempt(attempt);
  }, []);

  // Update callback refs when they change
  useEffect(() => {
    callbacksRef.current = {
      addMessage,
      addReaction,
      removeReaction,
      updateReaction,
      addImage,
      clearChatHistory,
      showNotification,
      updateRoomInfo,
      addHistoryMessages
    };
  }, [
    addMessage,
    addReaction,
    removeReaction,
    updateReaction,
    addImage,
    clearChatHistory,
    showNotification,
    updateRoomInfo,
    addHistoryMessages
  ]);

  // Add a new ref to track if we've loaded history
  const hasLoadedHistoryRef = useRef(false);

  const connect = useCallback(() => {
    // Use stable refs
    const callbacks = callbacksRef.current;
    const currentWsRef = wsRefStable.current;

    // Clear any existing connection
    if (currentWsRef.current) {
      currentWsRef.current.close();
      currentWsRef.current = null;
    }

    try {
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        setIsConnected(true);
        updateConnectionStatus('connected');
        currentWsRef.current = ws;

        // Only send join message if this is a new room or first connection
        if (currentRoomRef.current !== slug || !hasLoadedHistoryRef.current) {
          ws.send(JSON.stringify({
            type: "join",
            message: `${initialUsername} joined the room`,
            name: initialUsername,
            timestamp: Date.now()
          }));
          currentRoomRef.current = slug;
        }
      };

      ws.onclose = () => {

        // Only attempt reconnect if this is still the current connection
        if (currentWsRef.current === ws && retryAttempt < 5) {
          setIsConnected(false);

          const nextAttempt = retryAttempt + 1;
          const delay = Math.min(Math.pow(2, retryAttempt) * 1000, 30000);

          updateConnectionStatus(`Connection lost. Reconnecting in ${Math.round(delay / 1000)}s...`, nextAttempt);

          reconnectTimeoutRef.current = setTimeout(() => {
            connect();
          }, delay);
        } else if (retryAttempt >= 5) {
          updateConnectionStatus('Connection failed. Please refresh the page.');
        }
      };

      ws.onmessage = (event) => {
        if (currentWsRef.current !== ws && currentWsRef.current !== null) {
          return;
        }
        try {
          const data = JSON.parse(event.data);

          if (data.type === 'text') {
            callbacks.addMessage({
              type: 'text',
              messageId: data.messageId,
              sender: data.sender,
              content: data.content,
              timestamp: data.timestamp
            });
            if(data.sender === initialUsername){
              setIsMyMessage(true);
            }
          } else if (data.type === 'history') {
            if (!hasLoadedHistoryRef.current) {
              setIsHistoryLoading(false);
              hasLoadedHistoryRef.current = true;
              data.messages.forEach(msg => {
                callbacks.addMessage({
                  type: msg.type,
                  messageId: msg.messageId,
                  sender: msg.sender,
                  content: msg.content,
                  timestamp: msg.timestamp
                });
                msg.reactions.forEach(reaction => {
                  callbacks.addReaction({
                    messageId: msg.messageId,
                    reaction: reaction.reaction,
                    sender: reaction.sender,
                  });
                });
              });
              if (data.messages.length < 30) {
                setHasMoreHistory(false);
              }
            }
          } else if (data.type === 'reaction') {
            if (data.actionType === 'add') {
              callbacks.addReaction(data);
            } else if (data.actionType === 'remove') {
              callbacks.removeReaction(data);
            } else if (data.actionType === 'update') {
              callbacks.updateReaction(data);
            }
          } else if (data.type === 'file') {
            callbacks.addMessage({
              type: data.type,
              messageId: data.messageId,
              sender: data.sender,
              content: data.content,
              timestamp: data.timestamp
            });
            if(data.sender === initialUsername){
              setIsMyMessage(true);
            }
          } else if (data.type === 'memberCount') {
            updateRoomInfo(data);
          } else if (data.type === 'roominfo') {
            updateRoomInfo(data)
          }
        } catch (error) {
          console.error('Error parsing message:', error);
          setIsHistoryLoading(false);
        }
      };

      ws.onerror = (event) => {
        console.error('WebSocket error:', event);
        setIsConnected(false);
      };
    } catch (error) {
      console.error('WebSocket connection error:', error);
      setIsConnected(false);
    }
  }, [
    wsUrl,
    slug,
    initialUsername,
    retryAttempt,
    updateConnectionStatus,
    updateRoomInfo
  ]);

  // Connection effect
  useEffect(() => {
    setRetryAttempt(0);
    updateConnectionStatus('connecting');
    connect();

    // Cleanup function
    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
      if (wsRefStable.current?.current) {
        wsRefStable.current.current.close();
        wsRefStable.current.current = null;
      }
    };
  }, [slug, connect, updateConnectionStatus]);

  // Reset history loaded flag when changing rooms
  useEffect(() => {
    clearChatHistory();
    setIsHistoryLoading(true);
    hasLoadedHistoryRef.current = false;
  }, [slug, clearChatHistory]);

  useEffect(() => {
    if (isMyMessage) {
      chatul.current.scrollTop = chatul.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isHistoryLoading]);

  const showEmojiPicker = (rect, onEmojiSelect) => {
    const picker = document.createElement("div");
    picker.className = 'absolute z-[300] bg-gray-800 rounded-lg shadow-lg';
    picker.style.left = `${rect.left}px`;
    picker.style.top = `${rect.bottom + 5}px`;

    const emojiList = ['👍', '❤️', '😂', '😮', '😢', '👏', '🔥', '🎉'];

    emojiList.forEach(emoji => {
      const button = document.createElement("button");
      button.className = 'p-2 hover:bg-gray-700 rounded-lg';
      button.innerHTML = emoji;
      button.onclick = () => {
        onEmojiSelect(emoji);
        document.body.removeChild(picker);
      };
      picker.appendChild(button);
    });

    document.body.appendChild(picker);

    // Close picker when clicking outside
    const closeHandler = (e) => {
      if (!picker.contains(e.target)) {
        if (document.body.contains(picker)) {
          document.body.removeChild(picker);
        }
        document.removeEventListener('click', closeHandler);
      }
    };

    setTimeout(() => {
      document.addEventListener('click', closeHandler);
    }, 0);
  };

  const handleReaction = (messageId, emoji) => {
    if (wsRef.current && isConnected) {
      try {
        const payload = {
          type: "reaction",
          messageId: messageId,
          reaction: emoji,
          name: initialUsername,
          timestamp: Date.now()
        };
        wsRef.current.send(JSON.stringify(payload));
      } catch (error) {
        console.error('Error sending reaction:', error);
      }
    }
  };

  const handleSubmit = (e, fileData) => {
    e?.preventDefault();

    if (wsRef.current && isConnected) {
      try {
        let payload;

        if (fileData) {
          payload = {
            type: "file",
            content: fileData.map(file => file.url).join(','),
            name: initialUsername,
            timestamp: Date.now()
          };
        } else {
          // Handle text message
          const messageText = chatInputRef.current.value.trim();
          if (!messageText) return; // Don't send empty messages

          payload = {
            type: "message",
            content: messageText,
            name: initialUsername,
            timestamp: Date.now()
          };

          // Clear input and reset height after sending
          chatInputRef.current.value = '';
          chatInputRef.current.style.height = '44px'; // Reset to minimum height
        }

        wsRef.current.send(JSON.stringify(payload));
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 10) {
      alert('You can only upload a maximum of 10 files');
      return;
    }
    setAcceptedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 10,
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    // Store the current scroll position
    const scrollPos = textarea.scrollTop;

    // Reset height to auto and set a small height to properly calculate scrollHeight
    textarea.style.height = '0px';

    // Calculate the new height within limits (between 44px and 150px)
    const newHeight = Math.max(44, Math.min(textarea.scrollHeight, 150));
    textarea.style.height = newHeight + 'px';

    // Restore the scroll position if content is scrollable
    textarea.scrollTop = scrollPos;
  };

  const fetchMoreHistory = useCallback(async () => {
    const callbacks = callbacksRef.current;
    const chatContainer = chatul.current;
    
    // Store current scroll height and position before loading
    const prevScrollHeight = chatContainer.scrollHeight;
    const prevScrollTop = chatContainer.scrollTop;

    // Guard clauses
    if (!Array.isArray(messages) || messages.length === 0 || isLoadingMore) {
      setIsLoadingMore(false);
      setHasMoreHistory(false);
      return;
    }

    try {
      const firstMessage = messages[0];
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/cafes/history?start_point=${firstMessage.messageId}&slug=${roomInfo.slug}`);
      const data = await response.json();

      if (!Array.isArray(data) || data.length === 0) {
        setHasMoreHistory(false);
        return;
      }

      // Process messages
      data.forEach(msg => {
        callbacks.addHistoryMessages({
          type: msg.type,
          messageId: msg.id,
          sender: msg.sender,
          content: msg.content,
          timestamp: msg.created_at
        });

        if (Array.isArray(msg.reactions)) {
          msg.reactions.forEach(reaction => {
            callbacks.addReaction({
              messageId: msg.message_id,
              reaction: reaction.reaction,
              sender: reaction.sender,
            });
          });
        }
      });

      // If we received fewer messages than expected, there's no more history
      if (data.length < 30) { // Adjust this number based on your page size
        setHasMoreHistory(false);
      }

      // After processing messages, adjust scroll position
      requestAnimationFrame(() => {
        const newScrollHeight = chatContainer.scrollHeight;
        const heightDifference = newScrollHeight - prevScrollHeight;
        chatContainer.scrollTop = prevScrollTop + heightDifference;
      });

    } catch (error) {
      console.error('Error fetching history:', error);
      setHasMoreHistory(false);
    } finally {
      setIsLoadingMore(false);
    }
  }, [messages, isLoadingMore, roomInfo.slug]);

  useEffect(() => {
    const chatContainer = chatul.current;
    if (!chatContainer) return;

    let timeoutId = null;
    const handleScroll = () => {
      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Only proceed if we're near the top and not already loading
      if (chatContainer.scrollTop < 100 && !isLoadingMore && hasMoreHistory) {
        timeoutId = setTimeout(() => {
          setIsLoadingMore(true); // Set loading state before fetching
          fetchMoreHistory();
        }, 300); // 300ms debounce
      }
    };

    chatContainer.addEventListener('scroll', handleScroll);
    return () => {
      chatContainer.removeEventListener('scroll', handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fetchMoreHistory, isLoadingMore, hasMoreHistory]);

  return (
    <div {...getRootProps({ className: "relative flex h-full flex-col justify-end" })}>
      <div className="bg-gray-800 pt-4 pb-4 px-24 border-b border-gray-700">
        <h1 className="text-xl font-semibold">{roomInfo.name || 'Chat Room'}</h1>
        <div className="flex justify-between text-sm text-gray-400">
          <span>{roomInfo.total_members} Members</span>
          <span>{roomInfo.online_members} Online</span>
        </div>
      </div>

      {connectionStatus !== 'connected' && (
        <div className="absolute top-0 left-0 right-0 bg-yellow-500 text-black px-4 py-2 text-center">
          {connectionStatus}
        </div>
      )}

      <input {...getInputProps()} />
      {isDragActive && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white text-xl">Drop your images here...</div>
        </div>
      )}

      <div id="chatlog" ref={chatul} className="overflow-auto bg-gray-800 pl-2 h-full relative">
        {isLoadingMore && (
          <div className="flex justify-center py-2">
            <div className="animate-spin h-5 w-5 text-indigo-500">
              <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        )}
        {isHistoryLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="flex flex-col items-center space-y-4">
              <svg className="animate-spin h-10 w-10 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span className="text-white">Loading chat history...</span>
            </div>
          </div>
        )}
        <ul className="space-y-1">
          {messages.map((message, index) => (
            <MessageItem
              key={`ChatRoomMessage-${message.messageId}-${index}`}
              message={message}
              reactions={getMessageReactions(message.messageId)}
              onReaction={(emoji) => handleReaction(message.messageId, emoji)}
              onImageClick={(url, caption) => {
                setSelectedImage({
                  url,
                  messageId: message.messageId
                });
                setSelectedCaption(caption);
              }}
              initialUsername={initialUsername}
              showEmojiPicker={showEmojiPicker}
            />
          ))}
        </ul>
      </div>

      <div className="flex rounded-lg overflow-hidden w-full bg-gray-600">
        <FileUpload
          onFileUpload={(fileData) => {
            if (fileData) {
              handleSubmit(null, fileData);
            }
          }}
          acceptedFiles={acceptedFiles}
          initialUsername={initialUsername}
          disabled={isHistoryLoading}
        />
        <form onSubmit={(e) => handleSubmit(e)} className="flex items-center w-full m-0 p-0" autoComplete="off">
          <textarea
            ref={chatInputRef}
            rows="1"
            className="w-full px-4 py-3 bg-gray-600 focus:outline-none focus:ring-0 disabled:opacity-50 resize-none overflow-y-auto scrollbar-custom"
            disabled={isHistoryLoading || !isConnected}
            placeholder={isHistoryLoading ? "Loading..." : "Type a message... (Shift+Enter for new line)"}
            onKeyDown={handleKeyDown}
            onInput={handleTextareaInput}
            style={{
              minHeight: '44px',
              maxHeight: '150px'
            }}
          />
        </form>
        <EmojiPickerWrapper
          inputRef={chatInputRef}
          onEmojiSelect={() => { }}
        />
      </div>
      {selectedImage && (
        <Modal
          isOpen={!!selectedImage}
          onClose={() => {
            setSelectedImage(null);
            setSelectedCaption('');
          }}
        >
          <div className="flex flex-col items-center gap-4 w-full mx-auto">
            <div className="flex items-center justify-between w-full">
              <div className="flex-1 flex flex-col items-center relative group">
                <img
                  src={selectedImage.url}
                  alt="Preview"
                  className="max-w-full max-h-[80vh] object-contain rounded-lg"
                />

                {/* Reaction Container */}
                <div className="hidden group-hover:flex items-center gap-2 absolute right-4 top-4 bg-gray-700 rounded-full px-2 py-1">
                  <button
                    className="text-gray-400 hover:text-gray-200 text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      const rect = e.target.getBoundingClientRect();
                      showEmojiPicker(rect, (emoji) => {
                        if (wsRef.current) {
                          const payload = {
                            type: "reaction",
                            messageId: selectedImage.messageId,
                            name: initialUsername,
                            reaction: emoji
                          };
                          wsRef.current.send(JSON.stringify(payload));
                        }
                      });
                    }}
                  >
                    😊
                  </button>
                </div>

                {/* Reactions Display */}
                <div className="flex flex-wrap gap-1 mt-1">
                  {
                    Object.entries(
                      (getMessageReactions(selectedImage.messageId) || [])
                        .reduce((acc, curr) => {
                          acc[curr.reaction] = (acc[curr.reaction] || 0) + 1;
                          return acc;
                        }, {}) // Aggregate reactions
                    ).map(([emoji, count]) => (
                      <div
                        key={emoji}
                        className="bg-gray-700 rounded-full px-2 py-1 text-sm flex items-center gap-1"
                        data-emoji={emoji}
                        data-count={count}
                      >
                        {emoji} <span>{count}</span>
                      </div>
                    ))}
                </div>

                {selectedCaption && (
                  <div className="text-gray-300 text-center mt-2">
                    {selectedCaption}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
});

export default ChatRoom;