import { useEffect, useState } from 'react';

export const useNotifications = () => {
  const [permission, setPermission] = useState(Notification.permission);

  const requestPermission = async () => {
    try {
      const result = await Notification.requestPermission();
      setPermission(result);
      return result;
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return 'denied';
    }
  };

  const showNotification = (title, options = {}) => {
    if (permission === 'granted' && !document.hasFocus()) {
      try {
        const notification = new Notification(title, {
          icon: '/favicon.ico',
          ...options,
        });

        notification.onclick = () => {
          window.focus();
          notification.close();
        };
      } catch (error) {
        console.error('Error showing notification:', error);
      }
    }
  };

  useEffect(() => {
    if (permission === 'default') {
      requestPermission();
    }
  }, [permission]);

  return { showNotification, requestPermission, permission };
}; 