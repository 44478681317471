import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children, icon, submitText, onSubmit }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const modalContent = (
    <div 
      className="fixed inset-0 z-[100]" 
      aria-labelledby="modal-title" 
      role="dialog" 
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-[100] overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div 
            ref={modalRef} 
            className="relative transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all w-full max-w-[95vw] sm:max-w-lg"
          >
            <div className="px-4 py-5 sm:p-6">
              <div className="sm:flex sm:items-start">
                {icon === 'success' && (
                  <div className="mx-auto flex h-40 sm:h-60 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 w-auto">
                    <img className="h-full w-auto" src="https://cdn.thewatercooler.cafe/water-glass-icon_24911-115733-removebg-preview.png" alt="success icon" />
                  </div>
                )}
                {icon === 'fail' && (
                  <div className="mx-auto flex h-16 sm:h-20 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0">
                    <img className="h-full w-auto" src="https://cdn.thewatercooler.cafe/error-water-glass_24911-115784-removebg-preview.png" alt="failure icon" />
                  </div>
                )}
                <div className="mt-3 text-center sm:mt-0 sm:text-left text-white flex-1">
                  {children}
                </div>
              </div>
            </div>
            {(onClose || submitText) && (
              <div className="bg-gray-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {onClose && (
                  <button 
                    onClick={onClose} 
                    type="button" 
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-purple-700 px-3 py-2 text-sm font-semibold text-gray-200 hover:bg-purple-600 sm:mt-0 sm:w-auto mr-2"
                  >
                    Close
                  </button>
                )}
                {submitText && (
                  <button 
                    onClick={onSubmit} 
                    type="button" 
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-gray-200 hover:bg-indigo-500 sm:mt-0 sm:w-auto mr-2"
                  >
                    {submitText}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
};

export default Modal;