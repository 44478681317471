import React, { createContext, useState, useContext } from 'react';

const CategoryContext = createContext({
    categories: [],
    updateCategories: () => { },
});

export function CategoryProvider({ children }) {
  const [categories, setCategories] = useState([]);

  const updateCategories = (newCategories) => {
    setCategories(newCategories);
  };

  const value = {
    categories,
    updateCategories,
  };

  return (
    <CategoryContext.Provider value={value}>
      {children}
    </CategoryContext.Provider>
  );
}

export function useCategory() {
    const context = useContext(CategoryContext);
    if (context === undefined) {
        throw new Error('useCategory must be used within an CategoryProvider');
    }
    return context;
} 