import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/forest/index.css';

const VideoPlayer = ({ src, className }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, {
        controls: true,
        fluid: true,
        preload: 'metadata',
        responsive: true,
        playbackRates: [0.5, 1, 1.5, 2],
        muted: true,
        userActions: {
          hotkeys: true
        },
        controlBar: {
          children: [
            'playToggle',
            'volumePanel',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
            'playbackRateMenuButton',
            'muteToggle',
            'volumeControl',
            'fullscreenToggle'
          ]
        },
        html5: {
          vhs: {
            overrideNative: true
          }
        }
      });

      const player = playerRef.current;
      player.on('ready', () => {
        const unmuteButton = player.el().querySelector('.vjs-mute-control');
        if (unmuteButton) {
          unmuteButton.style.display = 'block';
        }
      });
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (playerRef.current && !playerRef.current.isDisposed()) {
            try {
              if (entry.isIntersecting) {
                playerRef.current.play().catch(() => {
                  // Silently handle autoplay failures
                  // Often occurs due to browser autoplay policies
                });
              } else {
                playerRef.current.pause();
              }
            } catch (error) {
              console.warn('Video player operation failed:', error);
            }
          }
        });
      },
      {
        threshold: 0.5
      }
    );

    const currentContainer = containerRef.current;

    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && !playerRef.current.isDisposed()) {
      playerRef.current.src({ src, type: 'video/webm' });
    }
  }, [src]);

  return (
    <div ref={containerRef} data-vjs-player>
      <video
        ref={videoRef}
        className={`video-js vjs-theme-forest ${className}`}
      />
    </div>
  );
};

export default VideoPlayer; 