import React, { useState, useEffect } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate and Link
import { useCategory } from '../context/CategoryContext';

const Menu = ({ onAddCafesClick }) => {
  const [inputValue, setInputValue] = useState('');
  const [cafes, setCafes] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { categories, updateCategories } = useCategory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cafesResponse, categoriesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_ENDPOINT}/cafes/search`),
          axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories`)
        ]);
        
        setCafes(cafesResponse.data.results);
        updateCategories(categoriesResponse.data.results);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputValueChange = ({ inputValue }) => {
    setInputValue(inputValue || '');
    const filtered = cafes
      .filter(cafe => cafe.slug.toLowerCase().includes((inputValue || '').toLowerCase()))
      .slice(0, 5);
    setSuggestions(filtered);
  };

  const handleSelect = (selectedItem) => {
    if (selectedItem) {
      navigate(`/c/${selectedItem.slug}`);
    }
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: suggestions,
    inputValue,
    onInputValueChange: handleInputValueChange,
    onSelectedItemChange: ({ selectedItem }) => handleSelect(selectedItem),
    itemToString: (item) => (item ? item.slug : 'No results'),
  });

  return (
    <div className=''>
      <div className="flex items-center justify-center p-4">
        <img 
          src="https://cdn.thewatercooler.cafe/thumbnail.webp" 
          alt="Logo" 
          className="h-24 w-24"
        />
      </div>
      <p className="text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-purple-500 text-2xl font-bold animate-gradient">
        The Water Cooler
      </p>


      <div className="mt-3 ml-2 mr-2 flex items-center bg-gray-800 rounded-lg px-4 py-2" {...getInputProps()}>
        <svg
          className="h-6 w-6 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.9 14.32a8 8 0 111.414-1.414l4.387 4.387a1 1 0 01-1.415 1.415l-4.387-4.387zM8 14a6 6 0 100-12 6 6 0 000 12z"
            clipRule="evenodd"
          />
        </svg>
        <input
          type="text"
          placeholder="Search for a cafe"
          className="bg-transparent border-none text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-0 ml-3 w-full"
          {...getInputProps()}
        />
      </div>

      <ul
        {...getMenuProps()}
        className={`bg-gray-800 rounded-lg p-2 absolute z-10 w-11/12 z-10 mt-2 ml-2 ${!isOpen ? 'hidden' : ''}`}
      >
        {isOpen &&
          suggestions.map((item, index) => (
            <li
              key={item.id || index}
              {...getItemProps({ item, index })}
              className={`text-white p-2 cursor-pointer hover:bg-gray-700 rounded ${highlightedIndex === index ? 'bg-gray-700' : ''
                }`}
            >
              {item.slug}
            </li>
          ))}
      </ul>

      <nav className='m-5'>
        <ul className="space-y-3">
          <li className="flex items-center space-x-3 p-2 rounded-md hover:bg-purple-800 hover:text-purple-600">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM8 11H6v2h2v-2zm6 0h-2v2h2v-2z" />
            </svg>
            <Link to="/" className="text-base text-gray-300">Home</Link>
          </li>
        </ul>

        <div className="mt-6">
          <h3 className="text-gray-500 text-sm uppercase mb-3">Categories</h3>
          <ul className="space-y-1">
            {categories.map((category) => (
              <li key={category.id} className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
                <Link to={`/chat_rooms/${category.category_name.toLowerCase()}`} className="flex items-center space-x-3 p-2">
                  <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
                  </svg>
                  <span className="text-base text-gray-300">{category.category_name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>

      <div className="absolute bottom-0 w-full">
        <div className="text-gray-500 text-xs flex justify-center items-center">
          <Link 
            to="/chat_rooms/all" 
            className="rounded-md bg-white/10 px-2.5 w-11/12 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 mb-6"
          >
            Manage Cafes
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Menu;