import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useSidebar } from '../context/sidebarContext';
import { useChatList } from '../context/ChatContext';
import { useWebSocket } from '../context/WebSocketContext';
import Modal from './Modal';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ initialUsername }) => {
  const { roomInfo } = useSidebar();
  const { messages, getMessageReactions } = useChatList();
  const { wsRef } = useWebSocket();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [lobbyImageUplodingHistory, setLobbyImageUplodingHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [hasMoreHistory, setHasMoreHistory] = useState(true);
  const historyContainerRef = useRef(null);
  const location = useLocation();
  const reloadTimerRef = useRef();
  const lobbyUrlRef = useRef(null);
  const currentOffsetRef = useRef(0);
  const lobbyImageUploadingHistoryRef = useRef(lobbyImageUplodingHistory);

  useEffect(() => {
    lobbyImageUploadingHistoryRef.current = lobbyImageUplodingHistory;
  }, [lobbyImageUplodingHistory]);

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const past = new Date(new Date(timestamp.replace(' ', 'T') + 'Z'));
    const diffInSeconds = Math.floor((now - past) / 1000);

    if (diffInSeconds < 60) return 'just now';

    const minutes = Math.floor(diffInSeconds / 60);
    if (diffInSeconds < 3600) return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;

    const hours = Math.floor(diffInSeconds / 3600);
    if (diffInSeconds < 86400) return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;

    const days = Math.floor(diffInSeconds / 86400);
    if (diffInSeconds < 2592000) return `${days} ${days === 1 ? 'day' : 'days'} ago`;

    const months = Math.floor(diffInSeconds / 2592000);
    if (diffInSeconds < 31536000) return `${months} ${months === 1 ? 'month' : 'months'} ago`;

    const years = Math.floor(diffInSeconds / 31536000);
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  };

  const sidebarImages = useMemo(() => {
    if (location.pathname === "/" && lobbyImageUplodingHistory) {
      return Object.values(lobbyImageUplodingHistory).flatMap(group => {
        return group.image_urls.map((url, index) => ({
          url,
          messageId: `lobby-${group.user_name}-${index}`,
          caption: `Uploaded by ${group.user_name}`,
          timestamp: group.timestamp
        }));
      });
    }
    return messages
      .filter(msg => msg.type === 'file')
      .flatMap(msg =>
        msg.content.split(',').map(url => ({
          url,
          messageId: msg.messageId,
          caption: msg.caption,
          timestamp: msg.timestamp
        }))
      )
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .slice(0, 18);
  }, [messages, lobbyImageUplodingHistory, location.pathname]);

  const handleImageClick = (index, messageId) => {
    if (location.pathname !== "/") {
      const correctIndex = sidebarImages.findIndex(img => img.messageId === messageId);
      setSelectedImageIndex(correctIndex);
      setSelectedMessageId(messageId);
    } else {
      lobbyUrlRef.current = messageId;
      setSelectedImageIndex(index);
      console.log(messageId);
    }
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prev) => {
      const newIndex = prev > 0 ? prev - 1 : sidebarImages.length - 1;
      setSelectedMessageId(sidebarImages[newIndex].messageId);
      return newIndex;
    });
  };

  const handleNext = () => {
    setSelectedImageIndex((prev) => {
      const newIndex = prev < sidebarImages.length - 1 ? prev + 1 : 0;
      setSelectedMessageId(sidebarImages[newIndex].messageId);
      return newIndex;
    });
  };

  const showEmojiPicker = (rect, onEmojiSelect) => {
    const picker = document.createElement("div");
    picker.className = 'absolute z-[300] bg-gray-800 rounded-lg shadow-lg';
    picker.style.left = `${rect.left}px`;
    picker.style.top = `${rect.bottom + 5}px`;

    const emojiList = ['👍', '❤️', '😂', '😮', '😢', '👏', '🔥', '🎉'];

    emojiList.forEach(emoji => {
      const button = document.createElement("button");
      button.className = 'p-2 hover:bg-gray-700 rounded-lg';
      button.innerHTML = emoji;
      button.onclick = () => {
        onEmojiSelect(emoji);
        document.body.removeChild(picker);
      };
      picker.appendChild(button);
    });

    document.body.appendChild(picker);

    const closeHandler = (e) => {
      if (!picker.contains(e.target)) {
        if (document.body.contains(picker)) {
          document.body.removeChild(picker);
        }
        document.removeEventListener('click', closeHandler);
      }
    };

    setTimeout(() => {
      document.addEventListener('click', closeHandler);
    }, 0);
  };

  const handleReaction = (messageId, emoji) => {
    if (wsRef.current) {
      const payload = {
        type: "reaction",
        messageId: messageId,
        reaction: emoji,
        name: initialUsername
      };
      wsRef.current.send(JSON.stringify(payload));
    }
  };

  const fetchNewLobbyData = useCallback(async () => {
    if (location.pathname !== "/") return;

    try {

      const token = localStorage.getItem('authToken');
      const startPoint = lobbyImageUploadingHistoryRef.current && Object.values(lobbyImageUploadingHistoryRef.current)[0]?.timestamp;

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/lobby/new?start_point=${startPoint || ''}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();

      if (data.groups && Object.keys(data.groups).length > 0) {
        setLobbyImageUplodingHistory(prev => {
          const newGroups = data.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {});
          
          const newState = { ...newGroups, ...(prev || {}) };
          return newState;
        });
      }
      reloadTimerRef.current = setTimeout(() => {
        fetchNewLobbyData();
      }, 60000);
    } catch (error) {
      console.error('Error fetching new lobby data:', error);
    }
  }, [location.pathname]);

  const fetchLobbyData = useCallback(async (reset = false) => {
    if (location.pathname !== "/") {
      return;
    }

    if (hasMoreHistory && !isHistoryLoading) {
      setIsHistoryLoading(true);
      const token = localStorage.getItem('authToken');
      const offset = reset ? 0 : (lobbyImageUplodingHistory ? currentOffsetRef.current : 0);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/lobby?offset=${offset}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();

        currentOffsetRef.current = Number(data.offset) + Number(data.length);

        setLobbyImageUplodingHistory(prev => {
          const newGroups = data.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {});
          
          const newState = !prev || reset ? newGroups : { ...prev, ...newGroups };
          return newState;
        });

        setHasMoreHistory(data.hasMore);
        setIsHistoryLoading(false);
        if (reset) {
          if (reloadTimerRef.current) {
            clearTimeout(reloadTimerRef.current);
          }
          reloadTimerRef.current = setTimeout(() => {
            fetchNewLobbyData();
          }, 60000);
        }
      } catch (error) {
        console.error('Error fetching lobby data:', error);
        setIsHistoryLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, hasMoreHistory, isHistoryLoading, lobbyImageUplodingHistory]);

  useEffect(() => {
    const currentPathname = location.pathname;

    if (currentPathname === "/") {
      fetchLobbyData(true);
    }

    return () => {
      if (reloadTimerRef.current) {
        clearTimeout(reloadTimerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") {
      const options = {
        root: null,
        rootMargin: '20px',
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isHistoryLoading && hasMoreHistory) {
          fetchLobbyData();
        }
      }, options);

      if (historyContainerRef.current) {
        observer.observe(historyContainerRef.current);
      }

      return () => observer.disconnect();
    }
  }, [roomInfo.slug, isHistoryLoading, hasMoreHistory, fetchLobbyData, location.pathname]);

  return (
    <div className='p-2'>
      <div className='pt-4'>
        <h1 className="text-xl font-semibold">{roomInfo.name || 'Chat Room'}</h1>
        <p className="text-gray-300">{roomInfo.description || 'Welcome to the chat room'}</p>

        <div className="pt-2 flex items-center justify-between">
          <div className="text-white font-bold text-sm sm:text-base">
            {roomInfo.total_members} Members
          </div>
          <div className="text-green-200 font-bold text-sm sm:text-base">
            {roomInfo.online_members} Online
          </div>
        </div>
      </div>

      {location.pathname !== "/" &&
        <>
          <div className="grid grid-cols-2 xs:grid-cols-3 gap-2 mt-4">
            {sidebarImages.map((imageData, index) => (
              <div
                key={`${imageData.messageId}-${index}`}
                className="aspect-square relative rounded-md overflow-hidden cursor-pointer hover:opacity-90 transition-opacity"
                onClick={() => handleImageClick(index, imageData.messageId)}
              >

                {imageData.url.toLowerCase().endsWith('.webm') ? (
                  <video
                    key={`video-${index}-${imageData.url}`}
                    src={imageData.url}
                    className="absolute inset-0 w-full h-full object-cover"
                    muted
                    loop
                    autoPlay
                    playsInline
                  />
                ) : (
                  <img
                    key={`img-${index}-${imageData.url}`}
                    src={imageData.url}
                    alt={`Chat ${index + 1}`}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                )}
              </div>
            ))}
          </div>

          <Link
            to={location.pathname.startsWith('/images/')
              ? `/c/${roomInfo.slug}`
              : `/images/${roomInfo.slug}`}
            className="block w-full mt-4 py-2 px-4 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors text-center"
          >
            {location.pathname.startsWith('/images/')
              ? 'Back to Chat Room'
              : 'See More Images'}
          </Link>
        </>
      }
      {location.pathname === "/" && (
        <div className="mt-4 flex-1 h-[calc(100vh-160px)] overflow-y-auto scrollbar-custom">
          {lobbyImageUplodingHistory && Object.values(lobbyImageUplodingHistory).map((group) => (
            <div key={group.id} className="mb-4 last:mb-0">
              <p className="text-sm text-gray-400 mb-2" title={group.timestamp}>
                User {group.user_name} uploaded {group.image_urls.length} {group.image_urls.length > 1 ? 'images' : 'image'} to <Link to={`/c/${group.cafe_slug}`} className="hover:text-indigo-400 red-text">
                  /c/{group.cafe_slug}
                </Link> {formatTimeAgo(group.timestamp)}
              </p>
              <div className="grid grid-cols-4 xs:grid-cols-6 gap-1">
                {group.image_urls.map((url, index) => {
                  return (
                    <div
                      key={url}
                      className="aspect-square relative rounded-md overflow-hidden cursor-pointer"
                      onClick={() => handleImageClick(index, url)}
                    >
                      {url.toLowerCase().endsWith('.webm') ? (
                        <video
                          key={`video-${index}-${url}`}
                          src={url}
                          className="absolute inset-0 w-full h-full object-cover"
                          muted
                          loop
                          autoPlay
                          playsInline
                        />
                      ) : (
                        <img
                          key={`img-${index}-${url}`}
                          src={url}
                          alt={`Preview`}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
          <div ref={historyContainerRef} className="h-4 w-full" />
          {isHistoryLoading && (
            <div className="flex justify-center py-4">
              <div className="animate-spin h-8 w-8 text-indigo-500">
                <svg className="animate-spin h-10 w-10 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedImageIndex !== null && (
        <Modal
          isOpen={selectedImageIndex !== null}
          onClose={() => setSelectedImageIndex(null)}
        >
          <div className="flex flex-col items-center gap-2 sm:gap-4 w-full mx-auto">
            <div className="flex items-center justify-between w-full">
              {location.pathname !== "/" && (
                <>
                  <button
                    onClick={handlePrevious}
                    className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
                  >
                    &#8249;
                  </button>

                  <div className="flex-1 flex flex-col items-center relative group px-2 sm:px-4">
                    {sidebarImages[selectedImageIndex].url.toLowerCase().endsWith('.webm') ? (
                      <video
                        key={`video-${selectedImageIndex}-${sidebarImages[selectedImageIndex].url}`}
                        src={sidebarImages[selectedImageIndex].url}
                        className="w-full max-w-[90vw] sm:max-w-[500px] h-auto max-h-[70vh] object-contain rounded-lg"
                        muted
                        loop
                        autoPlay
                        playsInline
                      />
                    ) : (
                      <img
                        key={`img-${selectedImageIndex}-${sidebarImages[selectedImageIndex].url}`}
                        src={sidebarImages[selectedImageIndex].url}
                        alt={`Chat ${selectedImageIndex + 1}`}
                        className="w-full max-w-[90vw] sm:max-w-[500px] h-auto max-h-[70vh] object-contain rounded-lg"
                      />
                    )}
                    <div className="absolute top-2 right-2 sm:top-4 sm:right-4 opacity-0 group-hover:opacity-100 transition-opacity">
                      <button
                        className="bg-gray-800 bg-opacity-75 hover:bg-opacity-90 rounded-full p-1.5 sm:p-2 text-gray-200"
                        onClick={(e) => {
                          e.stopPropagation();
                          const rect = e.target.getBoundingClientRect();
                          showEmojiPicker(rect, (emoji) => {
                            handleReaction(selectedMessageId, emoji);
                          });
                        }}
                      >
                        <span className="text-base sm:text-lg">😊</span>
                      </button>
                    </div>

                    <div className="flex flex-wrap gap-1 mt-2">
                      {selectedMessageId && Object.entries(
                        (getMessageReactions(selectedMessageId) || [])
                          .reduce((acc, curr) => {
                            acc[curr.reaction] = (acc[curr.reaction] || 0) + 1;
                            return acc;
                          }, {})
                      ).map(([emoji, count]) => (
                        <div
                          key={emoji}
                          className="bg-gray-700 rounded-full px-2 py-1 text-xs sm:text-sm flex items-center gap-1"
                        >
                          {emoji} <span>{count}</span>
                        </div>
                      ))}
                    </div>

                    {sidebarImages[selectedImageIndex].caption && (
                      <div className="text-gray-300 text-center mt-2 text-sm sm:text-base px-2">
                        {sidebarImages[selectedImageIndex].caption}
                      </div>
                    )}
                  </div>
                  <button
                    onClick={handleNext}
                    className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
                  >
                    &#8250;
                  </button>
                </>
              )}
              {location.pathname === "/" && (
                <div className="flex-1 flex flex-col items-center relative group px-2 sm:px-4">
                  {lobbyUrlRef.current.toLowerCase().endsWith('.webm') ? (
                    <video
                      key={`video-${lobbyUrlRef.current}`}
                      src={lobbyUrlRef.current}
                      className="w-full max-w-[90vw] sm:max-w-[500px] h-auto max-h-[70vh] object-contain rounded-lg"
                      muted
                      loop
                      autoPlay
                      playsInline
                    />
                  ) : (
                    <img
                      key={`img-${lobbyUrlRef.current}`}
                      src={lobbyUrlRef.current}
                      alt={`Preview`}
                      className="w-full max-w-[90vw] sm:max-w-[500px] h-auto max-h-[70vh] object-contain rounded-lg"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sidebar;