import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSidebar } from '../context/sidebarContext';
import Modal from './Modal';

const ImageGallery = React.memo(({ initialUsername }) => {
  const { roomInfo } = useSidebar();
  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [images, setImages] = useState([]);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const containerRef = useRef(null);

  const fetchImages = useCallback(async () => {
    setIsImagesLoading(true);
    const token = localStorage.getItem('authToken');
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/images?slug=${roomInfo.slug}&offset=${images.length}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    if (data.success && Array.isArray(data.images)) {
      setImages((prevImages) => [...prevImages, ...data.images]);
      setHasMoreImages(data.hasMore);
    } else {
      console.error('Unexpected data format:', data);
    }
    setIsImagesLoading(false);
  }, [roomInfo.slug, images.length]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isImagesLoading && hasMoreImages) {
        fetchImages();
      }
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [isImagesLoading, hasMoreImages, fetchImages]);

  const handlePrevious = () => {
    setSelectedImageIndex((prev) => {
      if (prev === null) return null;
      return prev > 0 ? prev - 1 : images.length - 1;
    });
  };

  const handleNext = () => {
    setSelectedImageIndex((prev) => {
      if (prev === null) return null;
      return prev < images.length - 1 ? prev + 1 : 0;
    });
  };

  return (
    <div className="bg-gray-900 p-4 sm:p-8 min-h-screen flex flex-col rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg sm:text-xl font-semibold leading-7 text-white">Image Gallery</h2>
      </div>

      <div id="create_cafe" className="mt-4 sm:mt-8 flex-grow overflow-auto">
        <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2 sm:gap-4">
          {images.map((image, index) => (
            <div
              key={image.id || index}
              className="aspect-square relative cursor-pointer hover:opacity-90 transition-opacity"
              onClick={() => setSelectedImageIndex(index)}
            >
              <img
                src={image}
                alt={"preview"}
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
              />
            </div>
          ))}
          {images.length === 0 && (
            <div className="col-span-full text-white text-center text-sm sm:text-base py-8">
              No images found
            </div>
          )}
          <div ref={containerRef} className="h-4 w-full col-span-full" />
        </div>
        {isImagesLoading && (
          <div className="flex justify-center py-4">
            <div className="animate-spin h-8 w-8 text-indigo-500">
              <svg className="animate-spin h-10 w-10 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={selectedImageIndex !== null}
        onClose={() => setSelectedImageIndex(null)}
      >
        <div className="flex flex-col items-center gap-4 w-full mx-auto p-2 sm:p-4">
          <div className="flex items-center justify-between w-full">
            <button
              onClick={handlePrevious}
              className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
            >
              &#8249;
            </button>

            <div className="flex-1 flex flex-col items-center relative group px-2 sm:px-4">
              {selectedImageIndex !== null && (
                <>
                  <img
                    src={images[selectedImageIndex]}
                    alt=""
                    className="w-full max-w-[250px] sm:max-w-[400px] md:max-w-[500px] h-auto max-h-[70vh] object-contain rounded-lg"
                  />
                </>
              )}
            </div>

            <button
              onClick={handleNext}
              className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
            >
              &#8250;
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default ImageGallery;