import React, { useRef, useState, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';

const EmojiPickerWrapper = ({ onEmojiSelect, inputRef, disabled }) => {
  const emojiPickerRef = useRef(null);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const onEmojiClick = (emojiData) => {
    if (inputRef?.current) {
      const cursorPosition = inputRef.current.selectionStart;
      const textBeforeCursor = inputRef.current.value.slice(0, cursorPosition);
      const textAfterCursor = inputRef.current.value.slice(cursorPosition);
      const newValue = textBeforeCursor + emojiData.emoji + textAfterCursor;
      inputRef.current.value = newValue;
      
      // Set cursor position after emoji
      const newCursorPosition = cursorPosition + emojiData.emoji.length;
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      inputRef.current.focus();
    }
    
    // Call the parent's onEmojiSelect callback with the emoji
    if (onEmojiSelect) {
      onEmojiSelect(emojiData.emoji);
    }
    
    setIsEmojiPickerVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      setIsEmojiPickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <button 
        className={`pr-6 border-r-4 border-gray-600 bg-gray-600 bounce emoji-button h-8 w-8 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={() => !disabled && setIsEmojiPickerVisible(!isEmojiPickerVisible)}
        tabIndex="-1"
        disabled={disabled}
      >
        {isEmojiPickerVisible ? '😮' : '😊'}
      </button>
      {isEmojiPickerVisible && !disabled && 
        <div className="absolute bottom-16 right-0 z-[200]" ref={emojiPickerRef}>
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            onEmojiClick={onEmojiClick}
            skinTonesDisabled={true}
            theme="dark"
          />
        </div>
      }
    </div>
  );
};

export default EmojiPickerWrapper;
